@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-Black.eot');
  src: local('Mulish Black'), local('Mulish-Black'),
  url('../fonts/Mulish/Mulish-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-Black.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-Black.woff') format('woff'),
  url('../fonts/Mulish/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-Medium.eot');
  src: local('../fonts/Mulish/Mulish Medium'), local('Mulish-Medium'),
  url('../fonts/Mulish/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-Medium.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-Medium.woff') format('woff'),
  url('../fonts/Mulish/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-ExtraBold.eot');
  src: local('Mulish ExtraBold'), local('Mulish-ExtraBold'),
  url('../fonts/Mulish/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-ExtraBold.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-ExtraBold.woff') format('woff'),
  url('../fonts/Mulish/Mulish-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-Light.eot');
  src: local('Mulish Light'), local('Mulish-Light'),
  url('../fonts/Mulish/Mulish-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-Light.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-Light.woff') format('woff'),
  url('../fonts/Mulish/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-Bold.eot');
  src: local('Mulish Bold'), local('Mulish-Bold'),
  url('../fonts/Mulish/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-Bold.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-Bold.woff') format('woff'),
  url('../fonts/Mulish/Mulish-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-Regular.eot');
  src: local('Mulish Regular'), local('Mulish-Regular'),
  url('../fonts/Mulish/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-Regular.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-Regular.woff') format('woff'),
  url('../fonts/Mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-ExtraLight.eot');
  src: local('Mulish ExtraLight'), local('Mulish-ExtraLight'),
  url('../fonts/Mulish/Mulish-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-ExtraLight.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-ExtraLight.woff') format('woff'),
  url('../fonts/Mulish/Mulish-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish/Mulish-SemiBold.eot');
  src: local('Mulish SemiBold'), local('Mulish-SemiBold'),
  url('../fonts/Mulish/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mulish/Mulish-SemiBold.woff2') format('woff2'),
  url('../fonts/Mulish/Mulish-SemiBold.woff') format('woff'),
  url('../fonts/Mulish/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
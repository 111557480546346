@use "variable";

%visible {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 18px;
  margin-left: -25px;
}
%space-between {
  display: flex;
  justify-content: space-between;
}
%minus_plus_before {
  width: 8px;
  height: 8px;
  right: 2px;
  position: relative;
}
.page.overview {
  .react-datepicker__input-container input {
    background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 35px 8px;
  }
  .table-container tr > :first-child {
    max-width: 275px;
    min-width: 275px;
    width: 275px;
  }
}
.react-datepicker {
  padding: 10px;
  width: 302px;
  font-family: variable.$body-font-family;
  &__navigation {
    top: 10px;
  }
  &__month-wrapper {
   @extend %space-between;
  }
  .react-datepicker__month .react-datepicker__month-text, {
    width: 64px;
    margin: 6px;
    padding: 5px;
    border-radius: 100px;
  }
  &__month-container {
    float: none;
  }
  &__month-text {
    font-size: 14px;
    font-weight: 400;
    padding: 3px;
  }
  &__header {
    background: variable.$color-white;
    border: none;
    font-size: 20px;
    font-weight: 600;
  }
  &__input-container {
    input {
      width: 250px;
      border: 1px solid #DFE0EB;
      border-radius: 4px;
      text-align: center;
      height: 32px;
      background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 55px 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      font-family: variable.$body-font-family;
      &:focus {
        border: 1px solid #DFE0EB;
        outline-offset: 0;
        outline: none;
      }
    }
  }
  &__close-icon {
    display: none;
  }
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    background-color: variable.$color-blue;
    color: variable.$color-white;
    &:hover {
      background-color: variable.$color-blue;
    }
  }
  &__day--selecting-range-end,
  &__month-text--selecting-range-end {
    background-color:  #4AC94F!important;
    color: variable.$color-white!important;
  }
  &__day--selecting-range-start,
  &__month-text--selecting-range-start {
    background-color: #3751ff!important;
    color: variable.$color-white!important;
  }
  &__day--in-selecting-range:not(.react-datepicker__day--in-range,
   .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  &__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  &__month-text--in-range,
  &__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #E7EBFF;
    color: black;
 }
  &__day--in-range,
  &__month-text--in-range {
    background-color: variable.$color-blue;
    &:hover {
      background-color: variable.$color-blue;
    }
  }
}
.react-datepicker-popper {
  z-index: 10;
}
.react-datepicker-wrapper {
  margin-bottom: 10px;
}

.block_chart_filters {
  display: flex;
  align-content: center;
  .page__block_chart {
    margin-bottom: 20px;
    background: variable.$color-white;
    border-radius: 8px;
    padding: 20px;
    margin-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 94%;
    height: 450px;
    border: 1px solid #dfe0eb;
    .recharts-responsive-container {
      margin-top: 10px;
    }
    .recharts-default-tooltip {
      display: flex;
      flex-direction: column-reverse;
    }
    .recharts-wrapper {
      line {
        display: none;
      }
      g.recharts-cartesian-grid-horizontal line{
        display: block !important;
      }
    }
    li.recharts-legend-item {
      display: none!important;
    }
  }
  .filters {
    min-width: 250px;
    width: 250px;
    background: variable.$color-white;
    padding: 0 5px 27px 0;
    border-radius: 8px;
    overflow: auto;
    max-height: 410px;
    position: relative;
    border: 1px solid #dfe0eb;
    &__header {
      position: sticky;
      top: 0;
      background: variable.$color-white;
      z-index: 3;
      padding: 27px 5px 24px 15px;
    }
    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 23px;
      letter-spacing: 0.4px;
      margin-bottom: 17px;
    }
    &__checkbox {
      &.all_geo {
        margin-bottom: 7px;
      }
      &__countries {
        padding: 24px 5px 0 15px;
      }
      &__bloks {
        column-count: 2;
        &.title {
          color: #9E9FA8;
          width: 100%;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.2px;
          padding: 10px 0;
        }
      }
    }
    .checked-block-buttons {
      @extend %space-between;
      flex-flow: row wrap;
      justify-content: start;
      width: 100%;
      margin-top: 18px;
      .button-checked {
        background: #D7DCFF;
        border-radius: 5px;
        width: 40px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        &:after {
          content: "x";
          margin-right: 5px;
          margin-top: -1px;
          display: block;
          float: right;
        }
        &:hover {
          background: #9BA6F7;
        }
      }
    }
    .checkbox-wrapper {
      background: rgb(231 235 255 / 20%);
      border-radius: 8px;
      padding: 18px 15px;
      margin: 4px 0;
      width: 100%;
      float: left;
      page-break-inside: avoid;
      break-inside: avoid;
      &.active {
        background: #DFE0EB;
      }
      &.all_geo {
        margin-bottom: 10px;
        position: relative;
        z-index: 10;
      }
      input {
        opacity: 0;
        position: absolute;
      }
      .cbx {
        -webkit-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          span {
            &:first-child {
              border-color: #3c53c7;
            }
          }
        }
        span {
          display: inline-block;
          vertical-align: middle;
          &:first-child {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transform: scale(1);
            vertical-align: middle;
            border: 2px solid #B9B8C3;
            transition: all 0.2s ease;
            svg {
              position: absolute;
              z-index: 1;
              top: 4px;
              left: 3px;
              fill: none;
              stroke: variable.$color-white;
              stroke-width: 2;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-dasharray: 16px;
              stroke-dashoffset: 16px;
              transition: all 0.3s ease;
              transition-delay: 0.1s;
              transform: translate3d(0, 0, 0);
            }
            &:before {
              content: "";
              width: 100%;
              height: 100%;
              background: variable.$color-blue;
              display: block;
              transform: scale(0);
              opacity: 1;
              border-radius: 50%;
              transition-delay: 0.2s;
            }
          }
          &:last-child {
            margin-left: 15px;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.2px;
            &:after {
              content: "";
              position: absolute;
              top: 8px;
              left: 0;
              height: 1px;
              width: 100%;
              background: #B9B8C3;
              transform-origin: 0 0;
              transform: scaleX(0);
            }
          }
        }
      }
      .inp-cbx:checked + .cbx span:first-child {
        border-color: variable.$color-blue;
        background: variable.$color-blue;
        animation: check 0.6s ease;
        svg {
          stroke-dashoffset: 0;
        }
        &:before {
          transform: scale(2.2);
          opacity: 0;
          transition: all 0.6s ease;
        }
      }
      .inp-cbx:checked + .cbx span:last-child {
        color: #252733;
        transition: all 0.3s ease;
        font-weight: 700;
      }
    }
  }
}

.table-container {
  border-collapse: collapse;
  position: relative;
  max-height:  400px;
  width: 100%;
  overflow: scroll;
  background: variable.$color-white;
  border-radius: 8px;
  display: block;
  border: 1px solid #dfe0eb;
  .header_table {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .header_table tr {
    height: 84px;
  }
  .name_row {
    @extend %space-between;
    align-items: center;
    .checkbox_table.Theoretical {
      display: none;
    }
    &.parent {
      height: 60px;
      color: variable.$color-blue;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: center;
      font-family: variable.$body-font-family;
      justify-content: flex-start;
      margin-left: 20px;
      &:before {
        background: url("../img/left_table_icon.svg") no-repeat;
        @extend %visible;
      }
      &.visible-parent {
        &:before {
          background: url("../img/down_table.svg") no-repeat;
          @extend %visible;
        }
      }
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #C5C7CD;
      margin-left: 5px;
      margin-top: 5px;
    }
    .tips_name {
      display: flex;
      align-items: center;
      .name {
        margin-right: 5px;
      }
      .image-container {
        cursor: pointer;
        .tooltip {
          position: absolute;
          z-index: 100;
          background: #DFE0EB;
          bottom: 45px;
          border-radius: 10px;
          border: 1px solid;
          font-size: 10px;
          font-weight: 600;
          width: 200px;
          white-space: normal;
          line-height: 15px;
          margin-left: -19px;
          .text {
            padding: 10px;
          }
          i {
            position: absolute;
            top: 100%;
            left: 16%;
            margin-left: -15px;
            width: 30px;
            height: 15px;
            overflow: hidden;
            &:after {
              content:'';
              position:absolute;
              width:10px;
              height:10px;
              left:30%;
              transform:translate(-50%,-50%) rotate(45deg);
              background-color: #e0deeb;
              border:1px solid;
            }
          }
        }
      }
    }
    .subItem {
      font-size: 12px;
      font-weight: 600;
    }
  }
  td, th {
    padding: 0 0 0 10px;
    max-width: 90px;
    min-width: 90px;
    min-height: 60px;
    background: variable.$color-white;
    box-sizing: border-box;
    white-space:pre;
    &.parent_table {
      &.sub {
        padding: 0 35px;
        text-transform: capitalize;
        &.visible {
          &:before {
            background: url("../img/down_table.svg") no-repeat;
            @extend %visible;
          }
        }
      }
      &:before {
        background: url("../img/left_table_icon.svg") no-repeat;
        @extend %visible;
      }
    }
    span {
      font-size: 10px;
    }
    .value_table {
      display: flex;
      .minus {
        color: #FA5F2E;
        &:before {
          content: url("../img/minus.svg");
          @extend %minus_plus_before;
        }
      }
      .plus {
        color: #24944A;
        &:before {
          content: url("../img/plus.svg");
          @extend %minus_plus_before;
        }
      }
    }
    span.percent {
      margin-left: 10px;
      span {
        font-size: 8px;
        &:first-child {
          font-size: 10px;
        }
      }
    }
  }
  thead th,
  tfoot th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  thead th:first-child,
  tfoot th:first-child {
    left: 0;
    z-index: 3;
  }
  tbody {
    overflow: scroll;
  }
  tr > :first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    color: #252733;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    max-width: 255px;
    min-width: 255px;
    width: 255px;
    height: 60px;
    border-bottom: 1px solid #DFE0EB;
    border-right: 1px solid #DFE0EB;
    padding: 0 16px;
  }
  #percent,
  #after,
  #Theoretical {
    display: none;
  }
}
.hidden-parent,
table.hidden {
  display: none;
}
.visible-parent,
table.visible {
  display: revert;
}
.checkbox_table {
  .high_load {
    display: none;
  }
  .high_load+.lb,
  .high_load+.lb::before,
  .high_load+.lb::after {
    transition: all .3s;
  }
  .high_load+.lb {
    display: flex;
    position: relative;
    width: 30px;
    height: 12px;
    border-radius: 25px;
    cursor: pointer;
  }
  .high_load+.lb::before {
    display: block;
    content: attr(data-offlabel);
    position: absolute;
    top: 18px;
    right: 10px;
    color: black;
    font-family: variable.$body-font-family;
    font-size: 19px;
  }
  .high_load+.lb::after {
    border-radius: 50%;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: variable.$color-white;
    border: 1px solid #83859B;
  }
  .high_load:checked+.lb::before {
    content: attr(data-onlabel);
    left: 16px;
    right: auto;
    color: variable.$color-white;
  }
  .high_load:checked+.lb::after {
    left: 19px;
    background-color: variable.$color-white;
  }
  .high_load+.lb {
    background-color: #ccc;
  }
  .high_load:checked+.lb {
    background: #7380EE;
  }
  .high_load:checked+.lb::before {
    color: variable.$color-white;
  }
}


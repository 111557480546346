@use "variable";

%flex {
  display: flex;
  align-items: center;
}

.dashboard.geo_adwords .page,
.dashboard.rtb_geo .page {
  top: 70px;
  max-width: 100%;
  width: 100%;
  position: fixed;
}
.filters_wrapper {
  display: flex;
  margin-bottom: 32px;
  margin-top: 14px;
  .search_input {
    margin-right: 30px;
    width: 240px;
    height: 32px;
    position: relative;
    z-index: 10;
    &.top {
      &:after {
        content: url('../img/geo_ico.svg');
        position: relative;
        top: -23px;
        z-index: 10;
        left: 15px;
      }
    }
    &.profit {
      &:after {
        content: '';
        background: url('../img/select_icon_profit.svg') no-repeat;
        background-size: 16px 15px;
        width: 16px;
        height: 15px;
        position: relative;
        top: -23px;
        z-index: 10;
        left: 15px;
        display: inline-block;
      }
    }
  }
  .react-datepicker-wrapper {
    margin-right: 30px;
    .react-datepicker__input-container {
      input {
        padding: 0 40px;
        text-align: unset;
        width: 230px;
        background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 15px 8px!important;
      }
    }
  }
  .field_group {
    height: 32px;
  }
}

.table_wrapper {
  background: variable.$color-white;
  border-radius: 8px;
  display: block;
  overflow: scroll;
  max-height: 720px;
  @media (max-width: 1500px) {
    max-height: 620px;
  }
  .name_row {
    @extend %flex;
    .code_country {
      color: rgba(0, 0, 0, 1);
      margin-right: 15px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 1);
      padding: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background-color: rgba(215, 220, 226, 1);
      text-transform: uppercase;
      width: 31px;
    }
  }
  .geo_th {
    text-align: left;
  }
  th {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    cursor: pointer;
    &.sortItem {
      &.active {
        &:after {
          content: url("../img/sort_icon.svg");
          display: inline-block;
          position: relative;
          transform: rotate(180deg);
          top: 0;
          left: 2px;
        }
        &.asc {
          &:after {
            transform: rotate(360deg);
            top: -2px;
          }
        }
      }
    }
    &.application_name {
      text-align: left;
    }
  }
  td {
    text-align: center;
    font-weight: 600;
  }
  .name_package_adwords {
    @extend %flex;
    cursor: pointer;
    .package_image {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .computer_comment_package_adwords {
      display: flex;
      align-items: start;
      flex-direction: column;
      .package {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 3px;
      }
      .sub_package {
        @extend %flex;
        .computer {
          font-size: 14px;
          line-height: 14px;
          font-weight: 900;
          color: rgba(5, 12, 190, 1);
          margin-right: 5px;
        }
        .comment {
          font-style: italic;
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
        }
      }
    }
    .icon_active_adwords {
      margin-right: 5px;
    }
  }
  .code_country_name {
    @extend %flex;
    margin-left: 30px;
    .code {
      color: rgb(0, 0, 0);
      margin-right: 15px;
      border-radius: 50%;
      border: 1px solid rgb(0, 0, 0);
      padding: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background-color: rgb(215, 220, 226);
      text-transform: uppercase;
      width: 31px;
    }
  }
}


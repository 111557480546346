@use "variable";

.sidebar {
  grid-area: sidebar;
  background: #363740;
  z-index: 100;
  min-height: 100%;
  &.overview_month {
    min-height: 150%;
  }
  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background: linear-gradient(100.17deg,#8089ea,#445bfc);
    .logo {
      vertical-align: middle;
      border-style: none;
      object-fit: cover;
      padding: 20px 0;
    }
  }
  &__link {
    border-bottom: 1px solid #DFE0EB;
    color: variable.$color-grey;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: center;
    padding: 30px 0 30px 25px;
    border-bottom: 1px solid rgba(223,224,235,.3);
    width: 100%;
    max-width: 100%;
    height: 81px;
    &__name {
      font-family: variable.$body-font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .2px;
      color: #a4a6b3;
      padding: 0 0 0 23px;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.overview {
        background: url("../img/overview_icon.svg") no-repeat;
      }
      &.subscriptions {
        background: url("../img/subscriptions_icon.svg") no-repeat;
      }
      &.apps_i {
        background: url("../img/apps_icon.svg") no-repeat;
      }
      &.rtb_geo {
        background: url("../img/rtb_icon.svg") no-repeat;
      }
      &.geo_adwords {
        background: url("../img/geo_ico_adwords.svg") no-repeat;
        width: 22px;
        height: 22px;
      }
      &.geo_settings {
        background: url("../img/geo_setings.svg") no-repeat;
      }
      &.package_settings {
        background: url("../img/pag_setings.svg") no-repeat;
      }
    }
    &.active {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 3px;
        height: 55px;
        border-radius: 5px;
        background: #7681ed;
      }
      .overview {
        background: url("../img/overview_icon_active.svg") no-repeat;
      }
      .subscriptions {
        background: url("../img/subscriptions_icon_active.svg") no-repeat;
      }
      .apps_i {
        background: url("../img/apps_icon_active.svg") no-repeat;
      }
      .rtb_geo {
        background: url("../img/rtb_icon_active.svg") no-repeat;
        width: 22px;
        height: 22px;
      }
      .geo_adwords {
        background: url("../img/geo_ico_adwords_active.svg") no-repeat;
      }
      .geo_settings {
        background: url("../img/geo_settings_active.svg") no-repeat;
      }
      .package_settings {
        background: url("../img/pag_setings_active.svg") no-repeat;
      }
    }
  }
  &__hide {
    position: relative;
    left: 25%;
    cursor: pointer;
    width: 10%;
  }
}
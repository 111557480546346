@use "variable";
@import "checkbox-wrapper";

.page {
  &.page_geo_settings {
    button.button_save_data {
      background-color: #3751FF;
      border: 1px solid #3751ff;
      border-radius: 8px;
      color: variable.$color-white;
      padding: 14px 32px 14px 32px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      cursor: pointer;
      &:hover {
        background-color: initial;
        color: #3751ff;
        border: 1px solid #3751ff;
      }
    }
    .geo_settings {
      &__body_page {
        padding: 0 0 0 60px;
      }
      &__filters {
        &.title {
          color: rgba(158, 159, 168, 1);
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0.3px;
        }
        &.checkbox_block {
          column-count: 3;
          margin-bottom: 60px;
          .checkbox-wrapper {
            padding: 18px 0;
          }
        }
        .checkbox-wrapper {
          .cbx span:first-child {
            background-color: #B9B8C3;
          }
        }
        @extend .checkbox-wrapper;
      }
    }
  }
}
@use 'variable';
@import "checkbox-wrapper";

%flex {
  display: flex;
  align-items: center;
}

.package_settings {
  .button_save_data {
    background-color: #3751FF;
    border-radius: 8px;
    color: variable.$color-white;
    padding: 14px 32px 14px 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    cursor: pointer;
    border: 1px solid #3751ff;
    &:hover {
      background-color: initial;
      color: #3751ff;
      border: 1px solid #3751ff;
    }
  }
  &__filters {
    &.checkbox_block {
      padding: 0 30px;
      @extend .checkbox-wrapper;
      .checkbox-wrapper {
        @extend %flex;
        justify-content: space-between;
        border-bottom: 1px solid variable.$color-grey;
        border-radius: 0;
        height: 60px;
        .package_img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
          margin-left: 40px;
          margin-right: 8px;
        }
        .package_manager {
          margin-left: 25px;
          color: #445bfc;
        }
        .package_computer {
          margin: 0 10px;
          color: #3751FF;
          font-weight: 900;
        }
        .package_comment {
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
        }
        .change_package {
          cursor: pointer;
        }
        .cbx span:first-child {
          background-color: #B9B8C3;
        }
      }
    }
    &.checkbox_block {
      margin-bottom: 40px;
    }
  }
}

.ReactModalPortal {
  font-family: variable.$body-font-family;
  .closed_modal {
    cursor: pointer;
  }
  .header_modal {
    @extend %flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &.title {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.2px;
    }
  }
  .package_form_body {
    @extend %flex;
    margin-bottom: 30px;
  }
  .package_form_submit {
    float: right;
    background: #3751FF;
    border-radius: 8px;
    border: none;
    color: variable.$color-white;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    padding: 14px 48px;
    cursor: pointer;
  }
  .form_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    label {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.2px;
      margin-right: 10px;
    }
    .computer_name_input,
    .comment_name_input,
    .package_name_input,
    .manager_name_input {
      background: #F8F8F8;
      height: 40px;
      border: none;
      border-radius: 8px;
      padding: 20px 11px;
      width: 360px;
    }
  }
  .image_input {
    margin-right: 40px;
    input {
      opacity: 0;
      position: absolute;
      width: 100px;
      height: 100px;
      left: 20px;
      cursor: pointer;
    }
    .preview {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      &:before {
        content: '';
        background: url("../img/load_icon.svg") no-repeat;
        width: 50px;
        height: 50px;
      }
    }
  }
}

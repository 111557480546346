@use "variable";

.top_bar {
  grid-area: header;
  padding: 20px 0;
  background: variable.$color-white;
  display: flex;
  justify-content: space-between;
  &__search_form {
    display: flex;
    align-items: center;
    position: relative;
    left: 30px;
    input {
      border: 1px solid #9FA2B4;
      max-width: 305px;
      width: 100vw;
      height: 50px;
      border-radius: 8px;
      padding: 10px 16px;
    }
  }
  &__panel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 40px;
  }
  &__user {
    display: flex;
    color: #252733;
    font-weight: bold;
    position: relative;
    right: 20px;
  }
  &__logout {
    button {
      background: variable.$color-blue;
      color: variable.$color-white;
      font-family: variable.$body-font-family;
      font-weight: bold;
      font-size: 19px;
      line-height: 24px;
      max-width: 140px;
      width: 140px;
      padding: 11px 30px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: initial;
        color: variable.$color-blue;
        border: 1px solid variable.$color-blue;
      }
    }
  }
}
@use "variable";
@use "../styles/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #F7F8FC;
  margin: 0;
  width: 100%;
  font-family: variable.$body-font-family;
}

%th_td {
  border-bottom: 1px solid #DFE0EB;
  font-size: 14px;
  line-height: 20px;
  font-family: variable.$body-font-family;
}

%percent_before {
  content: '';
  position: absolute;
  width: 5px;
  height: 100%;
  left: -7px;
}

.loader_page {
  position: absolute;
  background-size: 136% 133%;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 55, 64, 1);
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    top: 50%;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.dashboard {
  &.overview_month,
  &.overview {
    .top_bar {
      display: none;
    }
  }
}

.dashboard {
  display: grid;
  max-width: 100%;
  grid-template-areas:
        "sidebar header"
        "sidebar body";
  grid-template-rows: 80px 1fr auto;
  grid-template-columns: 300px 1fr 0;
  &.app_details_month,
  &.app_details_day {
    grid-template-rows: 0 1fr auto;
  }
  @media (max-width: 1366px) {
    grid-template-columns: 250px 1fr 0;
  }
  &.hide {
    grid-template-columns: 0 1fr 0;
    grid-template-rows: 0 1fr auto;
    .sidebar {
      display:  none;
    }
    .page {
      max-width: 100vw;
    }
    .top_bar {
      display: none;
    }
    .open_sidebar {
      cursor: pointer;
      margin-top: 40px;
      background: #fff;
      border: 1px solid #DFE0EB;
      position: relative;
      padding: 15px;
      z-index: 100;
      border-left: none;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
    &.marketing_view_campaign {
      .top_bar {
        display: flex;
        justify-content: space-between;
        height: 80px;
      }
    }
    .page {
      &.marketing_campaign {
        margin-top: 80px;
      }
    }
  }
  .page {
    grid-area: body;
    padding: 20px;
    max-width: 84vw;
    @media (max-width: 1600px) {
      max-width: 76vw;
    }
    &__header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-size: 19px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0.4px;
        margin-bottom: 8px;
      }
      &__description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9FA2B4;
      }
    }
    &.overview,
    &.overview_month {
      position: fixed;
      z-index: 10;
      max-width: 100vw;
      width: 99%;
      @media (max-width: 1440px) {
        position: absolute;
      }
    }
  }
  &.overview,
  &.overview_month {
    grid-template-rows: 0 1fr auto;
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  position: relative;
  top: 20px;
}
::-webkit-scrollbar-track {
  background: variable.$color-white;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #D0D1DF;
  border-radius: 9px;
  border: 1px solid #D0D1DF;
}

table {
  width: 100%;
  display: table;
  border-spacing: 0;
}

td {
  padding: 20px 20px;
  @extend %th_td;
}
th {
  text-align: left;
  padding: 30px 25px 15px;
  color: #C5C7CD;
  font-weight: 600;
  @extend %th_td;
}

.list_items {
  background: variable.$color-white;
  border-radius: 8px;
  &__image {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    position: relative;
    top: 5px;
  }
  .percent {
    margin: 0 0 0 10px;
    font-size: 8px;
    position: relative;
    top: -10px;
    &.low {
      color: #fa5f2e;
      &::after {
        background: url("../img/app_list_arrow_red.svg") no-repeat;
        @extend %percent_before;
        top: 7px;
      }
    }
    &.high {
      color: #24944a;
      &::after {
        background: url("../img/app_list_arrow_green.svg") no-repeat;
        transform: rotate(180deg);
        top: -7px;
        @extend %percent_before;
      }
    }
  }
  .percent_item {
    display: flex;
    min-width: 110px;
  }
}

.app-select-option {
  display: flex;
  align-items: center;
  padding: 10px;
  img {
    margin-right: 10px;
  }
  .option_computer {
    color: #3751FF;
    font-weight: 900;
    font-size: 16px;
  }
  .option_comment {
    font-style: italic;
    font-size: 12px;
  }
}

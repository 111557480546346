@use "variable";

%flex {
  display: flex;
  align-items: center;
}

%width_col {
  width: 340px!important;
  max-width: 340px;
  min-width: 340px;
}

%geo_before {
  position: relative;
  top: 30px;
  z-index: 10;
  left: 10px;
}

%th_td_total {
  border-bottom: none;
  text-align: center;
  width: 200px;
}

%title_table {
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

%percent_before {
  width: 8px;
  height: 8px;
  right: 2px;
  position: relative;
}

%width_percent {
  max-width: 100%;
  width: 100%;
}

.dashboard.app_list {
   .page {
      top: 70px;
      @extend %width_percent;
  }
}

.page.apps {
  padding: 10px 40px 10px 40px!important;
  position: absolute;
  &.versions {
    position: relative;
  }
  @extend %width_percent;
  .filters {
    &__block {
      display: flex;
    }
    &__search_input {
      width: 270px;
      height: 60px;
      position: relative;
      z-index: 10;
      &:first-child {
        margin-right: 30px;
      }
     &.date {
       &:before {
         content: url('../img/calendar.svg');
         @extend %geo_before;
       }
     }
      &.geo {
        &:before {
          content: url('../img/geo_ico.svg');
          @extend %geo_before;
        }
      }
    }
    .checked-block-buttons {
      display: flex;
      flex-flow: row wrap;
      align-content: space-between;
      justify-content: start;
      width: 100%;
      margin-top: 18px;
      .button-checked {
        background: #D7DCFF;
        border-radius: 5px;
        width: 40px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        &:after {
          content: "x";
          margin-right: 5px;
          margin-top: -1px;
          display: block;
          float: right;
        }
        &:hover {
          background: #9BA6F7;
        }
      }
    }
  }
  .title_table {
    margin-bottom: 8px;
    margin-top: 8px;
    @extend %title_table;
    color: #252733;
    margin-left: 20px
  }
  .total {
    margin-bottom: 10px;
    table {
      background: variable.$color-white;
      border-radius: 8px;
      border: 1px solid #DFE0EB;
    }
    &__first_col {
      color: #C5C7CD;
      @extend %title_table;
      border: none;
      position: relative;
      top: 20px;
      @extend %width_col;
      text-align: left!important;
    }
    th {
      @extend %th_td_total;
      padding: 5px 15px 5px 15px;
      &.position {
        position: relative;
        right: 5px;
      }
    }
    td {
      @extend %th_td_total;
      padding: 6px 0 6px 20px;
    }
  }
  .app_list_table {
    display: block;
    overflow: scroll;
    max-height: 620px;
    table {
      background: variable.$color-white;
      border-radius: 8px;
    }
    &__image {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
    &__aplication {
      .aplication_content {
        @extend %flex;
        .content {
          display: flex;
          align-items: start;
          flex-direction: column;
          &__app_name {
            font-weight: 500;
          }
          .computer_comment {
            text-align: left;
           .computer {
             color: #3751FF;
             font-weight: 900;
             font-size: 14px;
           }
            .comment {
              font-style: italic;
              font-size: 12px;
            }
          }
        }
      }
      padding: 20px 0 20px 20px !important;
      @extend %width_col;
    }
    &__aplication_version {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 385px;
    }
    &__aplication_version {
      min-height: 85px;
    }
    td {
      padding: 0 0 0 20px;
      text-align: center;
      width: 200px;
    }
    th {
      padding: 30px 15px 15px !important;
      text-align: center;
      width: 200px;
      cursor: pointer;  position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      background: #ffff;
      &.aplication {
        text-align: left;
      }
      &.sortItem {
        &.active {
          &:after {
            content: url("../img/sort_icon.svg");
            display: inline-block;
            position: relative;
            transform: rotate(180deg);
            top: 0;
            left: 2px;
          }
          &.asc {
            &:after {
              transform: rotate(360deg);
              top: -2px;
            }
          }
        }
      }
    }
  }

  span.percent {
    top: -10px;
    position: relative;
    margin-left: 5px;
    .plus {
      color: #24944A;
      &:before {
        content: url("../img/plus.svg");
        @extend %percent_before;
      }
    }
    .minus {
      color: #FA5F2E;
      &:before {
        content: url("../img/minus.svg");
        @extend %percent_before;
      }
    }
    span {
      font-size: 8px;
    }
  }
  .modal-date_option {
    position: fixed;
    top: 240px;
    left: 300px;
    justify-content: center;
    @extend %flex;
  }
  &.versions {
    .filters {
      &__search_input {
        height: auto;
      }

      &__wrapper {
        display: flex;
        align-items: start;
        gap: 16px;
        height: 100px;
      }
      .checkbox-wrapper_app_list {
        @extend %flex;
        gap: 6px;
      }
      .checked-block-buttons {
        margin-top: -10px;
      }
      .react-datepicker__input-container input {
        height: 40px;
        background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 35px 12px;
      }

      &__search_input {
        top: -18px;
      }
    }

    .total {
      margin-bottom: 0;
      &__first_col {
        top: 40px;
        width: 360px !important;
        max-width: 360px;
        min-width: 360px;
      }
      th {
        text-align: left;
        padding: 15px 25px 15px 25px;
      }
      td {
        padding: 6px 0 6px 25px;
        text-align: left;
        height: 66px;
      }
    }
    .table_app_list_version {
      margin-top: 40px;
      th {
       text-align: left;
      }
      td {
        text-align: left;
        padding: 6px 0 6px 25px;
        width: 200px;
        &.app_list_table__aplication_version {
          width: 360px!important;
        }
      }
    }
    .version_install_date {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .computer_comment{
      position: relative;
      left: 24px;
      .computer {
        color: #3751FF;
        font-weight: 900;
        font-size: 14px;
      }
      .comment {
        font-style: italic;
        font-size: 12px;
      }
    }
    .app_list_table__image {
      position: relative;
      top: 10px;
    }
    .content__app_name {
      white-space: break-spaces;
      line-break: anywhere;
    }
  }
}

.ab_test {
  .ab_test_item {
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(207, 211, 255, 1);
    &__title {
      background: rgba(207, 211, 255, 1);
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      letter-spacing: 2px;
      color: rgba(37, 39, 51, 1);
      padding: 14px 50px;
    }
    &__additions {
      display: flex;
      justify-content: space-between;
      align-items: start;
      width: 1230px;
      @media (max-width: 1400px) {
        width: 100%;
      }
    }
    .description_wrapper {
      width: 50%;
      max-width: 700px;
      position: relative;
      top: -50px;
    }
    .description {
      white-space: break-spaces;
      word-wrap: break-word;
      margin-bottom: 15px;
    }
    .link {
      margin-right: 20px;
      a {
       font-weight: 700;
        color: rgba(4, 0, 255, 1);
        font-style: italic;
        font-size: 11px;
        line-height: 14px;
      }
    }
    &__package {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 10px 20px;
      img {
        width: 37px;
        border-radius: 50%;
      }
    }
    &__package_computer {
      color: rgba(5, 12, 190, 1);
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
    }
    &__package_name {
      color: rgba(37, 39, 51, 1);
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }
    &__package_comment {
      font-style: italic;
      color: rgba(37, 39, 51, 1);
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }
    &__package_version {
      padding: 12px 0 0 80px;
      &:last-child {
        padding: 12px 0 32px 80px;
      }
    }
    &__version {
      @extend %flex;
      gap: 10px;
      width: 200px;
      white-space: break-spaces;
    }
    .trial_subscriptions {
      &__wrapper {
        padding: 12px 40px;
        font-size: 14px;
        line-height: 18px;
        color: rgba(37, 39, 51, 1);
        font-weight: 600;
      }
      &__item {
        @extend %flex;
        margin-bottom: 10px;
         &__name {
           margin-right: 5px;
         }
        &__subscriptions {
          position: relative;
          top: -10px;
          left: 4px;
        }
        &__price {
          color: rgba(151, 151, 151, 1);
          margin-left: 10px;
        }
        &__revenue {
          color: rgba(58, 125, 13, 1);
          margin-left: 10px;
        }
      }
    }
  }
  .filters__search_input {
    position: relative;
    top: -17px;
    max-width: 200px;
    left: 100px;
    &.geo {
      &:before {
        content: url('../img/geo_ico.svg');
        @extend %geo_before;
      }
    }
  }
}

.details {
  overflow: hidden;
  .table_container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  .filters {
    &__wrapper {
      display: flex;
      align-items: start;
      margin-bottom: 35px;
      .checkbox-wrapper_app_list {
        @extend %flex;
        gap: 10px;
        margin-left: 20px;
      }
      .checkbox-input_app_list {
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(223, 224, 235, 1);
        padding: 7px 10px;
        border-radius: 4px;
        @extend %flex;
        gap: 8px;
        .text_label {
          color: rgba(17, 38, 60, 1);
          font-size: 12px;
          line-height: 22px;
          font-weight: 400;
        }
        .high_load {
          display: none;
        }
        .high_load+.lb,
        .high_load+.lb::before,
        .high_load+.lb::after {
          transition: all .3s;
        }
        .high_load+.lb {
          display: flex;
          position: relative;
          width: 30px;
          height: 12px;
          border-radius: 25px;
          cursor: pointer;
        }
        .high_load+.lb::before {
          display: block;
          content: attr(data-offlabel);
          position: absolute;
          top: 18px;
          right: 10px;
          color: black;
          font-family: variable.$body-font-family;
          font-size: 19px;
        }
        .high_load+.lb::after {
          border-radius: 50%;
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: variable.$color-white;
          border: 1px solid #83859B;
        }
        .high_load:checked+.lb::before {
          content: attr(data-onlabel);
          left: 16px;
          right: auto;
          color: variable.$color-white;
        }
        .high_load:checked+.lb::after {
          left: 19px;
          background-color: variable.$color-white;
        }
        .high_load+.lb {
          background-color: #ccc;
        }
        .high_load:checked+.lb {
          background: #7380EE;
        }
        .high_load:checked+.lb::before {
          color: variable.$color-white;
        }
      }
    }
    .react-datepicker__input-container input {
      margin-right: 20px;
      height: 40px;
      background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 35px 12px;
    }
    .filters__search_input {
      height: 50px;
      &:first-child {
        margin-right: 0;
      }
    }
    .selected_multi_options {
      display: flex;
      flex-flow: row wrap;
      align-content: space-between;
      justify-content: start;
      width: 100%;
      .button-checked {
        background: #D7DCFF;
        border-radius: 5px;
        width: 40px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        &:after {
          content: "x";
          margin-right: 5px;
          margin-top: -1px;
          display: block;
          float: right;
        }
        &:hover {
          background: #9BA6F7;
        }
      }
    }
  }
  .table_title {
    @extend %flex;
    background: rgba(207, 211, 255, 1);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(37, 39, 51, 1);
    padding: 14px 0 14px 55px;
    position: relative;
    cursor: pointer;
    &.hidden {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .ARPU_talbe {
    &.hidden {
      display: none;
    }
    &.visible {
      display: block;
    }
  }
  table {
    background: rgba(255, 255, 255, 1);
    .hidden {
      display: none;
    }
    .visible {
      display: block;
    }
  }
  th {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(164, 168, 184, 1);
  }
  td {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(37, 39, 51, 1);
  }
  .item_name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(37, 39, 51, 1);
  }

  .ARPU_items_wrapper {
    &.visible {
      display: block;
    }
    &.hidden {
      display: none;
    }
    &__row {
      @extend %flex;
      background: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(223, 224, 235, 1);
      padding: 14px 0 14px 20px;
    }
    &__name {
      width: 60%;
    }
  }
  .name_ARPU_item {
    @extend %flex;
    background: rgba(232, 234, 254, 1);
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: rgba(114, 121, 159, 1);
    padding: 5px 0 5px 90px;
    cursor: pointer;
    &.hidden {
     img {
       transform: rotate(180deg);
     }
    }
    &__name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: rgba(37, 39, 51, 1);
    }
    &__value {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(37, 39, 51, 1);
    }
  }
  &.month {
    td {
      &.item_name {
        width: 150px;
      }
    }
    .ARPU_items_wrapper {
      &__name {
        width: 150px;
      }
    }
  }
  .selected_wrapper {
    width: 250px;
  }
  .first_registration {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 1);
  }
  .version_package_wrapper {
    margin-left: 20px;
    .selected_multi_options {
      margin-top: 10px;
    }
    .button-checked {
      width: 55px!important;
      padding: 5px;
    }
  }
}

.marketing_campaign {
  max-width: 76vw;
  th {
    padding: 30px 10px 15px;
  }
  &__parent {
    background: rgba(207, 212, 255, 1);
    color: rgba(37, 39, 51, 1);
    td {
      &:first-child {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        width: 450px !important;
        max-width: 450px !important;
        min-width: 450px !important;
      }
      width: 200px!important;
    }
  }
  .campaigns {
    td {
      &:first-child {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        width: 450px !important;
        max-width: 450px !important;
        min-width: 450px !important;
      }
      width: 200px!important;
    }
    &.visible {
      img {
        transform: rotate(0deg);
      }
      .ad_groups {
        display: block;
      }
    }
    &.hidden {
      img {
        transform: rotate(270deg);
      }
      .ad_groups {
        display: none;
      }
    }
  }
  .ad_groups {
    table {
      background: rgba(255, 255, 255, 1);
    }
    td {
      &:first-child {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }
    }
    &.visible {
      img {
        transform: rotate(0deg);
      }
      .creatives {
        display: block;
      }
    }
    &.hidden {
      img {
        transform: rotate(270deg);
      }
      .creatives {
        display: none;
      }
    }
  }
  .creatives {
    td {
      &:first-child {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
  &__item_name {
    display: flex!important;
    cursor: pointer;
    &.ad_groups {
      position: relative;
      left: 60px;
    }
    &.creatives {
      position: relative;
      left: 120px;
      cursor: auto;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker__input-container input {
    background: variable.$color-white url('../img/calendar.svg') no-repeat scroll 30px 8px;
  }
  .no_data {
    text-align: center;
    font-size: 16px;
    margin-top: 100px;
  }
  .paid_trials {
    position: relative;
    top: -14px;
    font-size: 8px;
    line-height: 20px;
    color: rgba(37, 39, 51, 1);
    font-weight: 400;
  }
}

@use "variable";
@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login_page {
  background: #363740;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.login_form {
  background: variable.$color-white;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px;
  width: 350px;
  margin: 0 auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__logo {
      max-width: 108px;
    }
    &__title {
      font-family: variable.$body-font-family;
      font-style: normal;
      text-align: center;
      letter-spacing: .3px;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #252733;
      padding: 10px 0 10px;
    }
    &__formText {
      font-family: variable.$body-font-family;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #9fa2b4;
      padding: 0 0 30px;
      text-align: center;
      font-style: normal;
      letter-spacing: .3px;
    }
  }
  &__field_group {
    display: flex;
    flex-direction: column;
    padding: 0 0 25px;
    label {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: .3px;
      text-transform: uppercase;
      color: #9fa2b4;
      padding: 0 0 6px;
    }
    input {
      background: #fcfdfe;
      border: 1px solid variable.$color-blue;
      box-sizing: border-box;
      border-radius: 8px;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: .3px;
      color: #252733;
      opacity: .4;
      width: 100%;
      padding: 11px;
    }
  }
  &__submit {
    width: 100%;
    background: variable.$color-blue;
    box-shadow: 0 4px 12px rgba(55,81,255,.24);
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 15px 0;
    font-family: variable.$body-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: .2px;
    color: variable.$color-white;
  }
}
@use "variable";

.checkbox-wrapper {
  border-radius: 8px;
  margin: 4px 0;
  width: 100%;
  float: left;
  page-break-inside: avoid;
  break-inside: avoid;
  input {
    opacity: 0;
    position: absolute;
  }
  .cbx {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      span {
        &:first-child {
          border-color: rgba(110, 202, 131, 1);
        }
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      &:first-child {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transform: scale(1);
        vertical-align: middle;
        border: 2px solid #B9B8C3;
        transition: all 0.2s ease;
        svg {
          position: absolute;
          z-index: 1;
          top: 8px;
          left: 6px;
          fill: none;
          stroke: variable.$color-white;
          stroke-width: 2;
          stroke-dashoffset: 0;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(110, 202, 131, 1);
          display: block;
          transform: scale(0);
          opacity: 1;
          border-radius: 50%;
          transition-delay: 0.2s;
        }
      }
      &:last-child {
        margin-left: 15px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        &:after {
          content: "";
          position: absolute;
          top: 8px;
          left: 0;
          height: 1px;
          width: 100%;
          background: #B9B8C3;
          transform-origin: 0 0;
          transform: scaleX(0);
        }
      }
    }
  }
  .inp-cbx:checked + .cbx span:first-child {
    border-color: rgba(110, 202, 131, 1);
    background: rgba(110, 202, 131, 1);
    animation: check 0.6s ease;
    &:before {
      transform: scale(2.2);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .inp-cbx:checked + .cbx span:last-child {
    color: #252733;
    transition: all 0.3s ease;
    font-weight: 700;
  }
  .label_checkbox {
    margin-left: 5px;
    display: flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.3px;
    .country_code {
      font-size: 20px;
      line-height: 24px;
    }
    .country_name {
      margin-left: 10px;
      font-size: 16px;
      line-height: 20px;
      color: rgba(158, 159, 168, 1);
    }
    .country_space {
      border-right: 1px solid #DFE0EB;
      margin-left: 9px;
      height: 20px;
    }
  }
}